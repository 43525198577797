import { ColumnType } from "antd/es/table";
import { AxiosResponse } from "axios";
import { UseMutationOptions } from "react-query3";

export enum AccessControlTypes {
  None,
  View = 1 << 0,
  Edit = 1 << 1,
  Create = 1 << 2,
  Delete = 1 << 3,
  Execute = 1 << 4,
  All = ~(~0 << 4)
}

export enum AccessControlObjectType {
  Script,
  Page
}

export interface AccessControl {
  id: number;
  type: AccessControlTypes;
  objectType: AccessControlObjectType;
  role: string;
  objectId: string;
  tag: string;
  resourceInfo?: ResourceInfo;
}

export type RoleGuardProps = {
  requiredRoles?: Array<string>;
  requiredAccessControls?: AccessControlTypes;
  accessControls?: AccessControlTypes;
  children?: any;
  allowedWithOneWayGitSync?: boolean;
  ignore?: boolean;
  noDemo?: boolean;
  fallback?: React.ReactElement;
  allowWhenNotEditing?: boolean;
};


export type FeatureGuardProps = {
  requiredFeature: Features;
};

export enum Features {
  None = 0,
  Api = 1,
  Scripts = 2,
  Terminals = 4,
  Dashboards = 8,
  Pages = 16,
  RateLimiting = 32,
  PublishedFolders = 64,
  Templates = 128,
  Protect = 256,
  CSharpLang = 64
}


export type AppToken = {
  accessControls?: AccessControlTypes;
  identity: Identity;
  id: number;
  expiration: Date;
  created: Date;
  revoked: boolean;
  role: string;
  revokedDate: Date;
  token: string;
  resourceInfo?: ResourceInfo;
};

export type Breakpoint = {
  id: number;
  processId: number;
  runspaceId: number;
  resourceInfo?: ResourceInfo;
};

export type ConfigurationResult = {
  success: boolean;
  error: string;
}

export type ChildProcess = {
  processId: number;
  processName: string;
  description: string;
  processUser: string;
  memory: number;
  cpu: number;
  environment: string;
}

export type ChildRunspace = {
  id: number;
  state: string;
  availability: string;
}

export type DebugAction = {
  breakpointId: number;
  type: DebugActionType;
  command: string;
}

export type DebugResult = {
  output: string;
}

export enum DebugActionType {
  Command,
  Continue,
  StepOver,
  StepInto,
  StepOut,
}

export type Notification = Partial<{
  id: number;
  createdTime: Date;
  createdBy: string;
  title: string;
  identity: Identity;
  description: string;
  job: Job;
  dashboard: Dashboard;
  script: Script;
  level: NotificationLevel;
  resourceInfo?: ResourceInfo;
}>;

export enum NotificationLevel {
  Information,
  Warning,
  Error
}

export type Authentication = {
  accessControls?: AccessControlTypes;
  id: number;
  type: AuthenticationMethodType;
  disabled: boolean;
  settings: string;
  source: string;
  resourceInfo?: ResourceInfo;
};

export enum DashboardStatus {
  Stopped,
  Started,
  StartFailed,
  Starting,
  Debugging,
  Deploying
}

export type Dashboard = Partial<{
  accessControls?: AccessControlTypes;
  authenticated: boolean;
  autoDeploy: boolean;
  baseUrl: string;
  content: string;
  dashboardComponents: Module[] | null;
  dashboardFramework: Framework;
  disableAutoStart: boolean;
  debug: boolean;
  environment: string;
  filePath: string;
  id: number;
  grantAppToken: boolean;
  deployed: boolean;
  lastDeploy: string;
  name: string;
  notes: string;
  processId: number;
  processName: string;
  resourceInfo?: ResourceInfo;
  role: string[];
  status: DashboardStatus;
  sessionTimeout: number;
  tag: Tag[];
  maintenance: boolean;
  verboseErrorMessages: boolean;
  disableInteractiveHost: boolean;
}>;

export type DashboardPage = Partial<{
  accessControls?: AccessControlTypes;
  resourceInfo?: ResourceInfo;

  id: string;
  modelId: number;
  name: string;
  url: string;
  description: string;
  role: string[];
  content: string;
}>;

export type DashboardPageEndpoint = {
  scriptBlock: string;
}


export type DashboardTotalSession = {
  resourceInfo?: ResourceInfo;
} & Number;

export type DashboardLog = {
  id: number;
  log: string;
  resourceInfo?: ResourceInfo;
};

export type DashboardDiagnostics = {
  memory: number;
  memoryHistory: number[];
  sessions: DashboardSession[];
  endpoints: DashboardEndpoint[];
  resourceInfo?: ResourceInfo;
};

export type DashboardEndpoint = {
  accessControls?: AccessControlTypes;
  id: string;
  averageExecutionTime: number;
  resourceInfo?: ResourceInfo;
};

export type Framework = {
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  version: string;
  path: string;
  latest: boolean;
  templates: string[];
  resourceInfo?: ResourceInfo;
};

export type Module = {
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  version: string;
  path: string;
  description: string;
  environment: string;
  readOnly: boolean;
  repository: string;
  resourceInfo?: ResourceInfo;
};

export type DashboardMarketplaceOverview = {
  newPackages?: DashboardMarketplaceModule[];
  mostDownloadedPackages?: DashboardMarketplaceModule[];
};

export interface SaveModuleProps {
  name: string;
  version: string;
  installed: boolean;
  mutationOptions?: UseMutationOptions<
    AxiosResponse<any>,
    unknown,
    { name: string; version: string },
    unknown
  >;
}

export type DashboardMarketplaceBrowsePage = {
  modules?: DashboardMarketplaceModule[];
  currentPage: number;
  totalPages: number;
  type: string;
  orderBy: string;
  totalItems: number;
  fromSearch?: boolean;
};

export type DashboardMarketplaceModule = {
  id: number;
  nuGetId: string;
  version: string;
  projectUrl: string;
  title: string;
  tags: string;
  summary: string;
  requireLicenseAcceptance: boolean;
  owners: string;
  published: Date;
  reportAbuseUrl: string;
  licenseUrl: string;
  iconUrl: string;
  downloadCount: number;
  description: string;
  authors: string;
  readme: string;
  installed: boolean;
  type: MarketplaceModuleType;
  fromSearch?: boolean;
  resourceInfo?: ResourceInfo;
};

export type Environment = {
  id: number;
  name: string;
  path: string;
  arguments?: string[];
  modules?: string[];
  variables: string[];
  pSModulePath?: string[];
  persistentRunspace: boolean;
  version: string;
  credential: string;
  description: string;
  reload: boolean;
  disableImplicitWinCompat: boolean;
  resourceInfo?: ResourceInfo;
};

export enum MarketplaceModuleType {
  Dashboard,
  Control,
  GitHubRepo,
  Tool,
}

export type DashboardSession = {
  accessControls?: AccessControlTypes;
  id: string;
  lastTouched: Date;
  userName: string;
  endpoints: Array<DashboardEndpoint>;
  resourceInfo?: ResourceInfo;
};

export declare const MethodName: ["GET", "POST", "DELETE", "PUT", "PATCH", "OPTIONS"];
export declare type MethodType = typeof MethodName[number];

export type Endpoint = {
  accessControls: AccessControlTypes;
  id?: number;
  url?: string;
  method?: MethodType[];
  scriptBlock?: string;
  authentication?: boolean;
  role?: Array<string> | null | undefined;
  regEx?: boolean;
  parts?: Part[];
  description: string;
  editable?: boolean;
  fullUrl: string;
  tag: Tag[];
  path: string;
  errorAction: number;
  environment: string;
  persistentLog: boolean;
  documentation: string;
  resourceInfo?: ResourceInfo;
};

export type EndpointDocumentation = {
  accessControls: AccessControlTypes;
  id?: number;
  name: string;
  description: string;
  authentication?: boolean;
  role?: Array<string> | null | undefined;
  definition: string;
  url: string;
  resourceInfo?: ResourceInfo;
};


export type Part = {
  isVariable: boolean;
  value: string;
};

export enum Feature {
  Api,
  Automation,
  Dashboard,
}

export type License = {
  accessControls?: AccessControlTypes;
  id: number;
  product: string;
  startDate: Date;
  endDate: Date;
  licensee: string;
  seats: number;
  licenseText: string;
  status: string;
  developer: boolean;
  resourceInfo?: ResourceInfo;
};

export type LicenseStatus = {
  product: string;
  expired: boolean;
  licensed: boolean;
  seats: number;
};

export type Identity = {
  accessControls?: AccessControlTypes;
  name: string;
  id: number;
  role?: Role;
  roleName?: string;
  localAccount: boolean;
  resourceInfo?: ResourceInfo;
};

export type PowerShellVersion = {
  id: number;
  version: string;
  path: string;
  resourceInfo?: ResourceInfo;
};

export type RateLimit = {
  accessControls?: AccessControlTypes;
  endpoint: string;
  id?: number;
  limit: number;
  period: string;
  resourceInfo?: ResourceInfo;
};

export interface Role {
  accessControls?: AccessControlTypes;
  name: string;
  description?: string;
  policy: string;
  id?: number;
  claimType?: string;
  claimValue?: string;
  defaultRoute?: string;
  resourceInfo?: ResourceInfo;
}

export type Tag = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  color: any;
  description: string;
  editable: boolean;
  resourceInfo?: ResourceInfo;
}>;

export type Computer = {
  id: number;
  name: string;
  heartBeat: Date;
  status: number;
  maintenance: boolean;
};

export enum JobStatus {
  Queued,
  Running,
  Completed,
  Failed,
  WaitingOnFeedback,
  Canceled,
  Canceling,
  Historical,
  Active,
  TimedOut,
  Warning
}

export interface ResourceInfo {
  parent?: string;
  self: string;
  schemaName: string;
}

export type Job = Partial<{
  feedback: JobFeedback;
  runAs: string;
  activity: null;
  agent: null;
  appToken: AppToken;
  children: null;
  computer: Computer;
  computerName: string;
  createdTime: Date;
  credential: any;
  currentOperation: string;
  debug: boolean;
  endTime: Date;
  environment: string | null;
  errorAction: ActionPreferenceType;
  id: number;
  identity: Identity;
  jobOutput: JobOutput;
  notes: string;
  output: string;
  parameters: JobParameter[];
  parentJob: Job;
  parentLineNumber: number;
  percentComplete: number;
  port: number;
  processId: number;
  runspaceId: number;
  scheduleId: number;
  scriptFullPath: string;
  script: Script;
  isScriptDeleted: boolean;
  secondsRemaining: number;
  startTime: Date;
  status: JobStatus;
  statusDescription: string;
  trigger: string;
  triggered: boolean;
  retryCount: number;
  memoryBytes: number;
  tags: string;
  schedule: string;
  archived: boolean;
  runId: string;
  resource: ResourceInfo;
}>;

export type JobParameter = Partial<{
  id: number;
  name: string;
  displayName: string;
  type: string;
  job: Job;
  value: string;
  vault: string;
  variable: Variable;
  displayType: ScriptParameterType;
  jsonData: string;
  file: PSUFile;
  formValue: any;
  resourceInfo?: ResourceInfo;
}>;

export type PSUFile = Partial<{
  id: number;
  fileName: string;
  resourceInfo?: ResourceInfo;
}>;

export type JobContext = Partial<{
  jobParameters: JobParameter[];
  parentJobId: number;
  lineNumber: number;
  debug: boolean;
  gotojob: boolean;
  computer: string;
  errorAction: ActionPreferenceType;
  environment: string | null;
  notes: string;
  credential: string | null;
  event: boolean;
  resourceInfo?: ResourceInfo;
}>;

export interface JobFeedback {
  id: number;
  data: string;
  message: string;
  complete: boolean;
  identity: Identity;
  job: Job;
  secure: boolean;
  resourceInfo: ResourceInfo;
}

export type JobOutput = {
  Id: number;
  Message: string;
  Type: JobOutputType;
  Data: string;
  Job: Job;
  Timestamp: Date;
  resourceInfo?: ResourceInfo;
};

export type JobStreamOutput = Partial<{
  JobId: number;
  Type: JobOutputType;
  Data: string;
  Timestamp: Date;
  resourceInfo?: ResourceInfo;
}>;

export type JobError = Partial<{
  type: JobOutputType;
  message: string;
  timestamp: string;
  resourceInfo?: ResourceInfo;
}>;

export enum JobOutputType {
  Information,
  Verbose,
  Debug,
  Warning,
  Error,
  Progress,
}

export type JobLog = {
  id: number;
  log: string;
  resourceInfo?: ResourceInfo;
};

export type JobPipelineOutput = {
  accessControls?: AccessControlTypes;
  id: number;
  data: string;
  jsonData: string;
  job: Job;
  resourceInfo?: ResourceInfo;
};

export type JobPipelineOutputData = {
  accessControls?: AccessControlTypes;
  title: string;
  name: string;
  value: string;
  key: string;
  isLeaf: boolean;
  selectable: boolean;
  resourceInfo?: ResourceInfo;
};

export type NotificationQuery = Partial<{
  hasPageProperties: boolean;
  orderBy: string;
  orderDirection: number;
  page: Notification[];
  skip: number;
  take: number;
  total: number;
}>;


export type JobQuery = Partial<{
  hasPageProperties: boolean;
  orderBy: string;
  orderDirection: number;
  page: Job[];
  skip: number;
  status: null;
  take: number;
  total: number;
}>;

export type Folder = {
  path: string;
  type: FolderType;
};

export enum FolderType {
  Unknown,
  Script,
  Dashboard,
  Page,
  Module
}

export enum ActionPreferenceType {
  SilentlyContinue,
  Stop,
  Continue,
  Inquire,
  Ignore,
  Suspend,
  Break,
}

export enum ScriptStatus {
  Draft,
  Pending_Review,
  Published,
  Disabled,
}

export enum ScriptParameterType {
  String,
  Integer,
  Boolean,
  DateTime,
  Select,
  Credential,
  StringArray,
}

export type ScriptHistory = {
  commitId: string;
  content: string;
  author: string;
  message: string;
  timestamp: Date;
};

export type ScriptTag = {
  id: number;
  tag: Tag;
  script: Script;
};

export type ScriptParameter = {
  id: number;
  name: string;
  displayName: string;
  type: string;
  script: Script;
  defaultValue: any;
  resolvedDefaultValue: any;
  required: boolean;
  displayType: ScriptParameterType;
  validValues: string[];
  helpText: string;
  parameterSet: string;
};

export type Script = Partial<{
  accessControls?: AccessControlTypes;
  commitId: string;
  commitNotes: string;
  concurrentJobs: number;
  content: string;
  createdTime: Date;
  credential: string;
  debug: boolean;
  description: string;
  disableManualInvocation: boolean;
  environment: string | null;
  errorAction: number;
  folder: string;
  fullPath: string;
  id: number;
  identity: Identity;
  informationAction: number;
  manualTime: number;
  maxHistory: number;
  name: string;
  requiredPowerShellVersion: string;
  scriptParameters: ScriptParameter[];
  status: number;
  tag: Tag[];
  timeOut: number;
  jobs: Job[];
  verbose: boolean;
  longDescription: string;
  links: string[];
  anonymous: boolean;
  defaultParameterSet: string;
  parameterSets: string[];
  resourceInfo?: ResourceInfo;
}>;

export type Template = Partial<{
  author: string;
  name: string;
  description: string;
  version: string;
  variables: Array<Variable>;
  downloads: number;
  readme: string;
  resourceInfo?: ResourceInfo;
}>;

export enum LogLevel {
  Debug,
  Information,
  Warning,
  Error,
}

export type Settings = Partial<{
  autoReload: boolean;
  apiEnvironment: string;
  concurrentJobLimit: number;
  databasePath: string;
  defaultEnvironment: string;
  defaultPage: string;
  defaultPowerShellVersion: PowerShellVersion | undefined;
  disableAutoReload: boolean;
  disableUpdateCheck: boolean;
  groomDays: number | undefined;
  hideApi: boolean;
  hideAutomation: boolean;
  hideDashboard: boolean;
  hideHomePage: boolean;
  hideRunAs: boolean;
  id: number;
  limitIdentities: boolean;
  loggingFilePath: string;
  logLevel: LogLevel;
  microsoftLogLevel: LogLevel;
  notificationLevel: NotificationLevel;
  rateLimitClientAllowList: string[] | undefined;
  rateLimitEndpointAllowList: string[] | undefined;
  rateLimitIpAddressAllowList: string[] | undefined;
  repositoryPath: string;
  securityEnvironment: string | undefined;
  securityPowerShellVersion: string | undefined;
  sessionTimeout: number;
  telemetry: boolean;
  gitSyncEnabled: boolean;
  gitRemote: string;
  gitBranch: string;
  gitSyncBehavior: GitSyncBehavior;
  scriptBaseFolder: string;
  adminConsoleTitle: string;
  adminConsoleLogo: string;
  pathDivider: string;
  groomInterval: number;
  proxyUri: string;
  proxyCredential: string;
  jobHandshakeTimeout: number;
  fallbackLanguageId: string;
  splatting: boolean
  favIcon: string;
  disabledFeatures: Features;
  dontLoadProfile: boolean
  windowsCompatEnabled: boolean;
  enhancedAppTokenSecurity: boolean;
  useLogoSize: boolean;
}>;

export enum GitSyncBehavior {
  TwoWay,
  OneWay,
  PushOnly
}

export interface Accessible {
  username: string;
  roles: string[];
  builtInRole: boolean;
  accessControls: userAccessControls;
  oneWayGitSync: boolean;
  manualGitMode: boolean;
  mode: string;
}

export interface userAccessControls {
  scripts: AccessControlTypes;
}

export type Stats = {
  jobsRunToday: number;
  jobsWaitingOnFeedback: number;
  jobsFailedToday: number;
  runningJobs: number;
  endpoints: number;
  runningDashboards: number;
  sessions: number;
  licensed: boolean;
  licensedName: string;
  licenseExpiration: string;
  defaultEnvironment: string;
  apiEnvironment: string;
  securityEnvironment: string;
  pages: number;
  scheduledJobs: number;
  licenseStatus: string;
  computer: string;
  processes: number;
  uptime: Date;
};

export interface Sso {
  enabled: boolean;
  resourceInfo?: ResourceInfo;
}

export interface PublishedFolder {
  accessControls?: AccessControlTypes;
  id: number;
  path: string;
  requestPath: string;
  authentication: boolean;
  role: string[] | null;
  defaultDocument: string[];
  name: string;
  resourceInfo?: ResourceInfo;
}

export type Update = {
  version?: string;
  releaseDate?: string;
  winZipUrl?: string;
  winMsiUrl?: string;
  linuxZipUrl?: string;
  osxZipUrl?: string;
  windows?: boolean;
  linux?: boolean;
  osx?: boolean;
  notes?: string;
};

export type Terminal = Partial<{
  id: number;
  name: string;
  description: string;
  environment: string;
  credential: string;
  role: string[];
  authentication: boolean;
  keepHistory: boolean;
  idleTimeout: number;
  resourceInfo?: ResourceInfo;
}>

export type TerminalInstance = Partial<{
  id: number;
  terminal: string;
  identity: Identity;
  resourceInfo?: ResourceInfo;
}>

export type TerminalHistory = Partial<{
  id: number;
  terminalInstance: TerminalInstance;
  input: string;
  output: string;
  timeStamp: Date;
  executionTime: string;
  resourceInfo?: ResourceInfo;
}>



declare const ThemeModeTypes: ["default", "dark", "compact"];
export declare type ThemeModeType = typeof ThemeModeTypes[number];

export type Theme = {
  mode: ThemeModeType;
  color: string;
};

export interface Variable {
  accessControls?: AccessControlTypes;
  id?: number;
  name: string;
  value: string;
  displayValue?: string;
  type?: string;
  createdTime?: Date;
  password?: any;
  secret?: boolean;
  updatedTime?: Date;
  userName?: any;
  vault?: string;
  editable?: boolean;
  description?: string;
  missingSecret?: boolean;
  disableRunAsSupport?: boolean;
  resourceInfo?: ResourceInfo;
}

export type ScheduleParameter = {
  name: string;
  value: string;
  type: ScriptParameterType;
};

export declare const TriggerEventTypes: [
  "JobCanceled",
  "JobFailed",
  "JobCompleted",
  "JobStarted",
  "JobFeedbackRequested",
  "ServerStarted",
  "ServerStopped",
  "DashboardStarted",
  "DashboardStopped",
  "UserLogin",
  "RevokedAppTokenUsage",
  "ProtectEvent",
  "NewUserLogin"
];

export declare type TriggerEventType = typeof TriggerEventTypes[number];

export type Trigger = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  script: string;
  eventType: string;
  environment: string;
  parameter: ScheduleParameter[];
  triggerScript: string;
  dashboard: string;
  editable: boolean;
  delay: number;
  protectRule: string;
  computer: string;
  resourceInfo?: ResourceInfo;
}>;

export type Schedule = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  identity: Identity;
  appToken: AppToken;
  cron: string;
  nextExecution: Date;
  description: string;
  script: string;
  timeZoneString: string;
  continuous: boolean;
  delay: number | string;
  credential: string;
  computer: string;
  oneTime: string;
  environment: string | null;
  type: "cron" | "continuous" | "delay" | "oneTime";
  datetime: Date;
  parameters: ScheduleParameter[];
  name: string;
  paused: boolean;
  randomDelay: boolean;
  resourceInfo?: ResourceInfo;
}>;

export type SystemEvent = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  script: string;
  type: string;
  eventClassName: string;
  condition: string;
  environment: string;
  credential: string;
  parameter: ScheduleParameter[];
  resourceInfo?: ResourceInfo;
}>;

export type UserSession = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  userName: string;
  source: string;
  connectionId: string;
  userAgent: string;
  connectedTime: Date;
  disconnectedTime?: Date;
  computerName: string;
  ipAddress: string;
  resourceInfo?: ResourceInfo;
}>;

export enum AuthenticationMethodType {
  Form,
  Windows,
  Certificate,
  OpenIDConnect,
  WSFederation,
  Saml2
}

export interface Configuration extends String {
  accessControls?: AccessControlTypes;
  resourceInfo?: ResourceInfo;
}

export type PSUTypes =
  | undefined
  | Configuration
  | Dashboard
  | Script
  | Framework
  | PublishedFolder
  | Trigger
  | RateLimit
  | Endpoint
  | DashboardEndpoint
  | DashboardSession
  | Module
  | Tag
  | JobPipelineOutput
  | JobPipelineOutputData
  | Role
  | Variable
  | Schedule
  | Identity
  | AppToken
  | Authentication
  | GitStatus
  | GitSettings
  | License
  | LoginPage
  | Page
  | Translation
  | DashboardPage;


export interface CustomColumns<T> extends ColumnType<T> {
  editable?: boolean;
  sortable?: boolean;
}
export interface GitStatus {
  accessControls?: AccessControlTypes;
  id: number;
  commitId: string;
  timestamp: Date;
  syncTime: Date;
  changes: number;
  location: string;
  remote: string;
  result: GitStatusResult;
  resultMessage: string;
  resourceInfo?: ResourceInfo;
}

export interface GitSettings {
  id: number;
  remote: string;
  branch: string;
  userName: string;
  password: string;
  initializeBehavior: GitInitializeBehavior;
  syncBehavior: GitSyncBehavior;
  interval: number;
  externalGit: boolean;
  bundleTimestamp: Date;
  bundlePath: string;
  resourceInfo?: ResourceInfo;
  accessControls?: AccessControlTypes;
}

export enum GitInitializeBehavior {
  Init,
  Clone
}

export interface GitChange {
  isDirectory: boolean;
  path: string;
  commitId: string;
  committer: string;
  notes: string;
  type: GitChangeType;
}


export interface GitCommit {
  message: string;
  author: string;
  timestamp: Date;
  sha: string;
}

export interface DataPage<T> {
  data: T[];
  total: number;
  skip: number;
  take: number;
}

export enum GitChangeType {
  Unmodified = 0,
  Added = 1,
  Deleted = 2,
  Modified = 3,
  Renamed = 4,
  Copied = 5,
  Ignored = 6,
  Untracked = 7,
  TypeChanged = 8,
  Unreadable = 9,
  Conflicted = 10
}


export enum FileStatus {
  /// <summary>
  /// The file doesn't exist.
  /// </summary>
  Nonexistent = (1 << 31),

  /// <summary>
  /// The file hasn't been modified.
  /// </summary>
  Unaltered = 0, /* GIT_STATUS_CURRENT */

  /// <summary>
  /// New file has been added to the Index. It's unknown from the Head.
  /// </summary>
  NewInIndex = (1 << 0), /* GIT_STATUS_INDEX_NEW */

  /// <summary>
  /// New version of a file has been added to the Index. A previous version exists in the Head.
  /// </summary>
  ModifiedInIndex = (1 << 1), /* GIT_STATUS_INDEX_MODIFIED */

  /// <summary>
  /// The deletion of a file has been promoted from the working directory to the Index. A previous version exists in the Head.
  /// </summary>
  DeletedFromIndex = (1 << 2), /* GIT_STATUS_INDEX_DELETED */

  /// <summary>
  /// The renaming of a file has been promoted from the working directory to the Index. A previous version exists in the Head.
  /// </summary>
  RenamedInIndex = (1 << 3), /* GIT_STATUS_INDEX_RENAMED */

  /// <summary>
  /// A change in type for a file has been promoted from the working directory to the Index. A previous version exists in the Head.
  /// </summary>
  TypeChangeInIndex = (1 << 4), /* GIT_STATUS_INDEX_TYPECHANGE */

  /// <summary>
  /// New file in the working directory, unknown from the Index and the Head.
  /// </summary>
  NewInWorkdir = (1 << 7), /* GIT_STATUS_WT_NEW */

  /// <summary>
  /// The file has been updated in the working directory. A previous version exists in the Index.
  /// </summary>
  ModifiedInWorkdir = (1 << 8), /* GIT_STATUS_WT_MODIFIED */

  /// <summary>
  /// The file has been deleted from the working directory. A previous version exists in the Index.
  /// </summary>
  DeletedFromWorkdir = (1 << 9), /* GIT_STATUS_WT_DELETED */

  /// <summary>
  /// The file type has been changed in the working directory. A previous version exists in the Index.
  /// </summary>
  TypeChangeInWorkdir = (1 << 10), /* GIT_STATUS_WT_TYPECHANGE */

  /// <summary>
  /// The file has been renamed in the working directory.  The previous version at the previous name exists in the Index.
  /// </summary>
  RenamedInWorkdir = (1 << 11), /* GIT_STATUS_WT_RENAMED */

  /// <summary>
  /// The file is unreadable in the working directory.
  /// </summary>
  Unreadable = (1 << 12), /* GIT_STATUS_WT_UNREADABLE */

  /// <summary>
  /// The file is <see cref="NewInWorkdir"/> but its name and/or path matches an exclude pattern in a <c>gitignore</c> file.
  /// </summary>
  Ignored = (1 << 14), /* GIT_STATUS_IGNORED */

  /// <summary>
  /// The file is <see cref="Conflicted"/> due to a merge.
  /// </summary>
  Conflicted = (1 << 15), /* GIT_STATUS_CONFLICTED */
}

export enum GitStatusResult {
  Running,
  Success,
  Failed,
  Skipped,
  EditInProgress,
  Conflict
}

export interface Page {
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  description: string;
  roles: string[];
  authenticated: boolean;
  components: any;
  layout: any;
  icon: string;
  showNavigation: boolean;
  showInNavigation: boolean;
  hideHeader: boolean;
  hideTitle: boolean;
  dataSource: string;
  dataSourceType: string;
  url: string;
  group: string;
  showLogout: boolean;
  resourceInfo?: ResourceInfo;
}

export type LoginPage = {
  id: number;
  image: string;
  title: string;
  primaryColor: string;
  secondaryColor: string;
  primaryFontColor: string;
  secondaryFontColor: string;
  headerFontColor: string;
  headerColor: string;
  copyright: string;
  resourceInfo?: ResourceInfo;
  links: Array<LoginPageLink>;
  accessControls?: AccessControlTypes;
  defaultLoginPage: boolean;
  defaultAuthentication: boolean;
  cssStylesheet: Array<string>;
  cssStylesheetIntegrity: Array<string>;
  appBarLogo: string;
  mode: string;
};

export type LoginPageLink = {
  text: string;
  url: string;
}

export type Translation = {
  id: number;
  languageId: string;
  name: string;
  strings: any;
  stringCount: number;
  accessControls?: AccessControlTypes;
  resourceInfo?: ResourceInfo;
}

// Desktop

export type FileAssociation = {
  id: number;
  script: string;
  credential: string;
  environment: string | null;
  extension: string;
  parameters: ScheduleParameter[];
  resourceInfo?: ResourceInfo;
}

export type Hotkey = {
  id: number;
  script: string;
  credential: string;
  environment: string | null;
  keys: Keys;
  modifierKeys: ModifierKeys;
  description: string;
  parameters: ScheduleParameter[];
  resourceInfo?: ResourceInfo;
}

export type ProtocolHandler = {
  id: number;
  script: string;
  credential: string;
  environment: string | null;
  protocol: string;
  parameters: ScheduleParameter[];
  resourceInfo?: ResourceInfo;
}

export enum Keys {
  //
  // Summary:
  //     The bitmask to extract modifiers from a key value.
  Modifiers = -65536,
  //
  // Summary:
  //     No key pressed.
  None = 0,
  //
  // Summary:
  //     The left mouse button.
  LButton = 1,
  //
  // Summary:
  //     The right mouse button.
  RButton = 2,
  //
  // Summary:
  //     The CANCEL key.
  Cancel = 3,
  //
  // Summary:
  //     The middle mouse button (three-button mouse).
  MButton = 4,
  //
  // Summary:
  //     The first x mouse button (five-button mouse).
  XButton1 = 5,
  //
  // Summary:
  //     The second x mouse button (five-button mouse).
  XButton2 = 6,
  //
  // Summary:
  //     The BACKSPACE key.
  Back = 8,
  //
  // Summary:
  //     The TAB key.
  Tab = 9,
  //
  // Summary:
  //     The LINEFEED key.
  LineFeed = 10,
  //
  // Summary:
  //     The CLEAR key.
  Clear = 12,
  //
  // Summary:
  //     The RETURN key.
  Return = 13,
  //
  // Summary:
  //     The ENTER key.
  Enter = 13,
  //
  // Summary:
  //     The SHIFT key.
  ShiftKey = 16,
  //
  // Summary:
  //     The CTRL key.
  ControlKey = 17,
  //
  // Summary:
  //     The ALT key.
  Menu = 18,
  //
  // Summary:
  //     The PAUSE key.
  Pause = 19,
  //
  // Summary:
  //     The CAPS LOCK key.
  Capital = 20,
  //
  // Summary:
  //     The CAPS LOCK key.
  CapsLock = 20,
  //
  // Summary:
  //     The IME Kana mode key.
  KanaMode = 21,
  //
  // Summary:
  //     The IME Hanguel mode key. (maintained for compatibility; use HangulMode)
  HanguelMode = 21,
  //
  // Summary:
  //     The IME Hangul mode key.
  HangulMode = 21,
  //
  // Summary:
  //     The IME Junja mode key.
  JunjaMode = 23,
  //
  // Summary:
  //     The IME final mode key.
  FinalMode = 24,
  //
  // Summary:
  //     The IME Hanja mode key.
  HanjaMode = 25,
  //
  // Summary:
  //     The IME Kanji mode key.
  KanjiMode = 25,
  //
  // Summary:
  //     The ESC key.
  Escape = 27,
  //
  // Summary:
  //     The IME convert key.
  IMEConvert = 28,
  //
  // Summary:
  //     The IME nonconvert key.
  IMENonconvert = 29,
  //
  // Summary:
  //     The IME accept key, replaces System.Windows.Forms.Keys.IMEAceept.
  IMEAccept = 30,
  //
  // Summary:
  //     The IME accept key. Obsolete, use System.Windows.Forms.Keys.IMEAccept instead.
  IMEAceept = 30,
  //
  // Summary:
  //     The IME mode change key.
  IMEModeChange = 31,
  //
  // Summary:
  //     The SPACEBAR key.
  Space = 32,
  //
  // Summary:
  //     The PAGE UP key.
  Prior = 33,
  //
  // Summary:
  //     The PAGE UP key.
  PageUp = 33,
  //
  // Summary:
  //     The PAGE DOWN key.
  Next = 34,
  //
  // Summary:
  //     The PAGE DOWN key.
  PageDown = 34,
  //
  // Summary:
  //     The END key.
  End = 35,
  //
  // Summary:
  //     The HOME key.
  Home = 36,
  //
  // Summary:
  //     The LEFT ARROW key.
  Left = 37,
  //
  // Summary:
  //     The UP ARROW key.
  Up = 38,
  //
  // Summary:
  //     The RIGHT ARROW key.
  Right = 39,
  //
  // Summary:
  //     The DOWN ARROW key.
  Down = 40,
  //
  // Summary:
  //     The SELECT key.
  Select = 41,
  //
  // Summary:
  //     The PRINT key.
  Print = 42,
  //
  // Summary:
  //     The EXECUTE key.
  Execute = 43,
  //
  // Summary:
  //     The PRINT SCREEN key.
  Snapshot = 44,
  //
  // Summary:
  //     The PRINT SCREEN key.
  PrintScreen = 44,
  //
  // Summary:
  //     The INS key.
  Insert = 45,
  //
  // Summary:
  //     The DEL key.
  Delete = 46,
  //
  // Summary:
  //     The HELP key.
  Help = 47,
  //
  // Summary:
  //     The 0 key.
  D0 = 48,
  //
  // Summary:
  //     The 1 key.
  D1 = 49,
  //
  // Summary:
  //     The 2 key.
  D2 = 50,
  //
  // Summary:
  //     The 3 key.
  D3 = 51,
  //
  // Summary:
  //     The 4 key.
  D4 = 52,
  //
  // Summary:
  //     The 5 key.
  D5 = 53,
  //
  // Summary:
  //     The 6 key.
  D6 = 54,
  //
  // Summary:
  //     The 7 key.
  D7 = 55,
  //
  // Summary:
  //     The 8 key.
  D8 = 56,
  //
  // Summary:
  //     The 9 key.
  D9 = 57,
  //
  // Summary:
  //     The A key.
  A = 65,
  //
  // Summary:
  //     The B key.
  B = 66,
  //
  // Summary:
  //     The C key.
  C = 67,
  //
  // Summary:
  //     The D key.
  D = 68,
  //
  // Summary:
  //     The E key.
  E = 69,
  //
  // Summary:
  //     The F key.
  F = 70,
  //
  // Summary:
  //     The G key.
  G = 71,
  //
  // Summary:
  //     The H key.
  H = 72,
  //
  // Summary:
  //     The I key.
  I = 73,
  //
  // Summary:
  //     The J key.
  J = 74,
  //
  // Summary:
  //     The K key.
  K = 75,
  //
  // Summary:
  //     The L key.
  L = 76,
  //
  // Summary:
  //     The M key.
  M = 77,
  //
  // Summary:
  //     The N key.
  N = 78,
  //
  // Summary:
  //     The O key.
  O = 79,
  //
  // Summary:
  //     The P key.
  P = 80,
  //
  // Summary:
  //     The Q key.
  Q = 81,
  //
  // Summary:
  //     The R key.
  R = 82,
  //
  // Summary:
  //     The S key.
  S = 83,
  //
  // Summary:
  //     The T key.
  T = 84,
  //
  // Summary:
  //     The U key.
  U = 85,
  //
  // Summary:
  //     The V key.
  V = 86,
  //
  // Summary:
  //     The W key.
  W = 87,
  //
  // Summary:
  //     The X key.
  X = 88,
  //
  // Summary:
  //     The Y key.
  Y = 89,
  //
  // Summary:
  //     The Z key.
  Z = 90,
  //
  // Summary:
  //     The left Windows logo key (Microsoft Natural Keyboard).
  LWin = 91,
  //
  // Summary:
  //     The right Windows logo key (Microsoft Natural Keyboard).
  RWin = 92,
  //
  // Summary:
  //     The application key (Microsoft Natural Keyboard).
  Apps = 93,
  //
  // Summary:
  //     The computer sleep key.
  Sleep = 95,
  //
  // Summary:
  //     The 0 key on the numeric keypad.
  NumPad0 = 96,
  //
  // Summary:
  //     The 1 key on the numeric keypad.
  NumPad1 = 97,
  //
  // Summary:
  //     The 2 key on the numeric keypad.
  NumPad2 = 98,
  //
  // Summary:
  //     The 3 key on the numeric keypad.
  NumPad3 = 99,
  //
  // Summary:
  //     The 4 key on the numeric keypad.
  NumPad4 = 100,
  //
  // Summary:
  //     The 5 key on the numeric keypad.
  NumPad5 = 101,
  //
  // Summary:
  //     The 6 key on the numeric keypad.
  NumPad6 = 102,
  //
  // Summary:
  //     The 7 key on the numeric keypad.
  NumPad7 = 103,
  //
  // Summary:
  //     The 8 key on the numeric keypad.
  NumPad8 = 104,
  //
  // Summary:
  //     The 9 key on the numeric keypad.
  NumPad9 = 105,
  //
  // Summary:
  //     The multiply key.
  Multiply = 106,
  //
  // Summary:
  //     The add key.
  Add = 107,
  //
  // Summary:
  //     The separator key.
  Separator = 108,
  //
  // Summary:
  //     The subtract key.
  Subtract = 109,
  //
  // Summary:
  //     The decimal key.
  Decimal = 110,
  //
  // Summary:
  //     The divide key.
  Divide = 111,
  //
  // Summary:
  //     The F1 key.
  F1 = 112,
  //
  // Summary:
  //     The F2 key.
  F2 = 113,
  //
  // Summary:
  //     The F3 key.
  F3 = 114,
  //
  // Summary:
  //     The F4 key.
  F4 = 115,
  //
  // Summary:
  //     The F5 key.
  F5 = 116,
  //
  // Summary:
  //     The F6 key.
  F6 = 117,
  //
  // Summary:
  //     The F7 key.
  F7 = 118,
  //
  // Summary:
  //     The F8 key.
  F8 = 119,
  //
  // Summary:
  //     The F9 key.
  F9 = 120,
  //
  // Summary:
  //     The F10 key.
  F10 = 121,
  //
  // Summary:
  //     The F11 key.
  F11 = 122,
  //
  // Summary:
  //     The F12 key.
  F12 = 123,
  //
  // Summary:
  //     The F13 key.
  F13 = 124,
  //
  // Summary:
  //     The F14 key.
  F14 = 125,
  //
  // Summary:
  //     The F15 key.
  F15 = 126,
  //
  // Summary:
  //     The F16 key.
  F16 = 127,
  //
  // Summary:
  //     The F17 key.
  F17 = 128,
  //
  // Summary:
  //     The F18 key.
  F18 = 129,
  //
  // Summary:
  //     The F19 key.
  F19 = 130,
  //
  // Summary:
  //     The F20 key.
  F20 = 131,
  //
  // Summary:
  //     The F21 key.
  F21 = 132,
  //
  // Summary:
  //     The F22 key.
  F22 = 133,
  //
  // Summary:
  //     The F23 key.
  F23 = 134,
  //
  // Summary:
  //     The F24 key.
  F24 = 135,
  //
  // Summary:
  //     The NUM LOCK key.
  NumLock = 144,
  //
  // Summary:
  //     The SCROLL LOCK key.
  Scroll = 145,
  //
  // Summary:
  //     The left SHIFT key.
  LShiftKey = 160,
  //
  // Summary:
  //     The right SHIFT key.
  RShiftKey = 161,
  //
  // Summary:
  //     The left CTRL key.
  LControlKey = 162,
  //
  // Summary:
  //     The right CTRL key.
  RControlKey = 163,
  //
  // Summary:
  //     The left ALT key.
  LMenu = 164,
  //
  // Summary:
  //     The right ALT key.
  RMenu = 165,
  //
  // Summary:
  //     The browser back key (Windows 2000 or later).
  BrowserBack = 166,
  //
  // Summary:
  //     The browser forward key (Windows 2000 or later).
  BrowserForward = 167,
  //
  // Summary:
  //     The browser refresh key (Windows 2000 or later).
  BrowserRefresh = 168,
  //
  // Summary:
  //     The browser stop key (Windows 2000 or later).
  BrowserStop = 169,
  //
  // Summary:
  //     The browser search key (Windows 2000 or later).
  BrowserSearch = 170,
  //
  // Summary:
  //     The browser favorites key (Windows 2000 or later).
  BrowserFavorites = 171,
  //
  // Summary:
  //     The browser home key (Windows 2000 or later).
  BrowserHome = 172,
  //
  // Summary:
  //     The volume mute key (Windows 2000 or later).
  VolumeMute = 173,
  //
  // Summary:
  //     The volume down key (Windows 2000 or later).
  VolumeDown = 174,
  //
  // Summary:
  //     The volume up key (Windows 2000 or later).
  VolumeUp = 175,
  //
  // Summary:
  //     The media next track key (Windows 2000 or later).
  MediaNextTrack = 176,
  //
  // Summary:
  //     The media previous track key (Windows 2000 or later).
  MediaPreviousTrack = 177,
  //
  // Summary:
  //     The media Stop key (Windows 2000 or later).
  MediaStop = 178,
  //
  // Summary:
  //     The media play pause key (Windows 2000 or later).
  MediaPlayPause = 179,
  //
  // Summary:
  //     The launch mail key (Windows 2000 or later).
  LaunchMail = 180,
  //
  // Summary:
  //     The select media key (Windows 2000 or later).
  SelectMedia = 181,
  //
  // Summary:
  //     The start application one key (Windows 2000 or later).
  LaunchApplication1 = 182,
  //
  // Summary:
  //     The start application two key (Windows 2000 or later).
  LaunchApplication2 = 183,
  //
  // Summary:
  //     The OEM Semicolon key on a US standard keyboard (Windows 2000 or later).
  OemSemicolon = 186,
  //
  // Summary:
  //     The OEM 1 key.
  Oem1 = 186,
  //
  // Summary:
  //     The OEM plus key on any country/region keyboard (Windows 2000 or later).
  Oemplus = 187,
  //
  // Summary:
  //     The OEM comma key on any country/region keyboard (Windows 2000 or later).
  Oemcomma = 188,
  //
  // Summary:
  //     The OEM minus key on any country/region keyboard (Windows 2000 or later).
  OemMinus = 189,
  //
  // Summary:
  //     The OEM period key on any country/region keyboard (Windows 2000 or later).
  OemPeriod = 190,
  //
  // Summary:
  //     The OEM question mark key on a US standard keyboard (Windows 2000 or later).
  OemQuestion = 191,
  //
  // Summary:
  //     The OEM 2 key.
  Oem2 = 191,
  //
  // Summary:
  //     The OEM tilde key on a US standard keyboard (Windows 2000 or later).
  Oemtilde = 192,
  //
  // Summary:
  //     The OEM 3 key.
  Oem3 = 192,
  //
  // Summary:
  //     The OEM open bracket key on a US standard keyboard (Windows 2000 or later).
  OemOpenBrackets = 219,
  //
  // Summary:
  //     The OEM 4 key.
  Oem4 = 219,
  //
  // Summary:
  //     The OEM pipe key on a US standard keyboard (Windows 2000 or later).
  OemPipe = 220,
  //
  // Summary:
  //     The OEM 5 key.
  Oem5 = 220,
  //
  // Summary:
  //     The OEM close bracket key on a US standard keyboard (Windows 2000 or later).
  OemCloseBrackets = 221,
  //
  // Summary:
  //     The OEM 6 key.
  Oem6 = 221,
  //
  // Summary:
  //     The OEM singled/double quote key on a US standard keyboard (Windows 2000 or later).
  OemQuotes = 222,
  //
  // Summary:
  //     The OEM 7 key.
  Oem7 = 222,
  //
  // Summary:
  //     The OEM 8 key.
  Oem8 = 223,
  //
  // Summary:
  //     The OEM angle bracket or backslash key on the RT 102 key keyboard (Windows 2000
  //     or later).
  OemBackslash = 226,
  //
  // Summary:
  //     The OEM 102 key.
  Oem102 = 226,
  //
  // Summary:
  //     The PROCESS KEY key.
  ProcessKey = 229,
  //
  // Summary:
  //     Used to pass Unicode characters as if they were keystrokes. The Packet key value
  //     is the low word of a 32-bit virtual-key value used for non-keyboard input methods.
  Packet = 231,
  //
  // Summary:
  //     The ATTN key.
  Attn = 246,
  //
  // Summary:
  //     The CRSEL key.
  Crsel = 247,
  //
  // Summary:
  //     The EXSEL key.
  Exsel = 248,
  //
  // Summary:
  //     The ERASE EOF key.
  EraseEof = 249,
  //
  // Summary:
  //     The PLAY key.
  Play = 250,
  //
  // Summary:
  //     The ZOOM key.
  Zoom = 251,
  //
  // Summary:
  //     A constant reserved for future use.
  NoName = 252,
  //
  // Summary:
  //     The PA1 key.
  Pa1 = 253,
  //
  // Summary:
  //     The CLEAR key.
  OemClear = 254,
  //
  // Summary:
  //     The bitmask to extract a key code from a key value.
  KeyCode = 65535,
  //
  // Summary:
  //     The SHIFT modifier key.
  Shift = 65536,
  //
  // Summary:
  //     The CTRL modifier key.
  Control = 131072,
  //
  // Summary:
  //     The ALT modifier key.
  Alt = 262144
}

export enum ModifierKeys {
  None,
  Alt = 1,
  Ctrl = 2,
  Shift = 4,
  Win = 8
}

// End Desktop

// File Explorer

export interface FileSystemItem {
  name: string;
  fullName: string;
  content: string;
  items: Array<FileSystemItem>;
  isLeaf: boolean;
}


// File Explorer

// Protect

export type ProtectEvent = Partial<{
  id: number;
  computerName: string;
  rule: string;
  script: string;
  contentPath: string;
  applicationName: string;
  userName: string;
  administrator: boolean;
  domainName: string;
  timestamp: Date;
}>

export type ProtectConfiguration = Partial<{
  name: string;
  description: string;
  id: number;
  builtIn: ProtectBuiltIn;
  license: string;
  rules: Array<ProtectRule>;
  actions: Array<ProtectAction>;
  resourceInfo?: ResourceInfo;
}>

export interface ProtectBuiltIn {
  enabled: boolean;
  disabledConditions: Array<string>;
  actions: Array<ProtectActionRef>;
}

export interface ProtectRule {
  name: string;
  anyCondition: boolean;
  conditions: Array<ProtectCondition>;
  actions: Array<ProtectActionRef>;
}

export interface ProtectAction {
  name: string;
  type: string;
  settings: Array<ProtectSetting>;
}

export interface ProtectActionRef {
  name: string;
}

export interface ProtectCondition {
  property: string;
  operator: string;
  value: string;
}

export interface ProtectSetting {
  name: string;
  value: string;
}

// End Protect