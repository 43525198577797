import React, { useState } from "react";
import { Button, Space, Modal as AntModal, Typography, Spin, Alert, Table } from "antd";
import useAppStore from "components/context/app/Hooks";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import queryClient, { useMutation } from "components/utils/queryClient";
import { message } from "antd/es";
import RoleGuard from "components/standalone/role-guard";
import { useQuery } from "react-query3";
import { Link } from "react-router-dom";
import { toRelativeUrl } from "components/utils/utils";
import { GitChange, GitStatusResult } from "types";

export default function GitEditButton() {
    const { userData, isEditing } = useAppStore();
    const [discardModalOpen, setDiscardModalOpen] = useState(false);
    const [discarding, setDiscarding] = useState(false);
    const [startingEdit, setStartingEdit] = useState(false);

    const { mutateAsync } = useMutation();

    const { data: status } = useQuery<GitStatusResult>("/gitstatus/status");

    const { data: changes, isLoading } = useQuery<GitChange[]>("/gitstatus/edit/changes", {
        enabled: discardModalOpen
    });

    const onOk = React.useCallback(
        async () => {
            setStartingEdit(true);
            await mutateAsync({ key: "/gitstatus/edit", action: "create" }, {
                onSuccess: () => {
                    setStartingEdit(false);
                    queryClient.invalidateQueries("/gitstatus/edit");
                },
                onError: () => {
                    setStartingEdit(false);
                    message.error("Failed to start editing.");
                }
            })

        }, [mutateAsync]);


    const onDiscard = React.useCallback(
        async () => {
            setDiscarding(true);
            await mutateAsync({ key: "/gitstatus/edit", action: "delete" }, {
                onSuccess: () => {
                    queryClient.invalidateQueries("/gitstatus/edit");
                    setDiscardModalOpen(false);
                    setDiscarding(false);
                },
                onError: () => {
                    message.error("Failed to cancel editing.");
                    setDiscardModalOpen(false);
                    setDiscarding(false);
                }
            })
        }, [mutateAsync]);


    const onDiscardClick = () => {
        if (status === GitStatusResult.EditInProgress) {
            setDiscardModalOpen(true);
        } else {
            onDiscard();
        }
    }

    if (!userData?.manualGitMode) return <></>

    if (status === GitStatusResult.Conflict) {

        return <Alert type="error" message="There are conflicts in your repository. Please resolve them before committing." showIcon action={<Link to={toRelativeUrl("/admin/settings/git/commit")}><Button size="small" danger>Resolve Conflict</Button></Link>} />
    }

    return <>
        {!isEditing && (
            <RoleGuard requiredRoles={["Administrator", "Operator"]} allowedWithOneWayGitSync={false} noDemo allowWhenNotEditing={true}>
                <Button icon={<EditOutlined />} onClick={onOk} loading={startingEdit}>Edit</Button>
            </RoleGuard>
        )}
        <Space direction="horizontal" hidden={!isEditing}>
            <Alert type="warning" message="Git sync does not pull during edits. Save or discard changes to allow git sync to pull." showIcon />
            {discarding ? <></> : <Link to={toRelativeUrl("/admin/settings/git/commit")}><Button title="Save Changes" icon={<SaveOutlined />}>View Changes</Button></Link>}
            <RoleGuard requiredRoles={["Administrator", "Operator"]} allowedWithOneWayGitSync={false} noDemo allowWhenNotEditing={true}>
                <Button loading={discarding} icon={<DeleteOutlined />} onClick={onDiscardClick}>{status === GitStatusResult.EditInProgress ? "Discard Changes" : "Cancel Edit"}</Button>

                <AntModal open={discardModalOpen} onOk={onDiscard} onCancel={() => setDiscardModalOpen(false)}>
                    <Typography>Are you sure you want to discard the following changes?</Typography>
                    {isLoading ? <Spin tip="Loading changes..." /> : <Table dataSource={changes} columns={[
                        {
                            title: "File",
                            dataIndex: "path",
                            key: "path"
                        }
                    ]}></Table>}
                </AntModal>
            </RoleGuard>

        </Space>
    </>
}
